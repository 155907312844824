<template lang="pug">
  v-container(fluid)
    v-row
      v-col(cols="12")
        v-autocomplete(
          v-model="incubatorRackListGroupKey"
          label="Incubator"
          :items="incubatorRackListGroupKeys"
          item-value="id"
          item-text="code"
          dense
          hide-details
        )
      v-col(cols="12")
        v-text-field(
          label="Date"
          type="date"
          hide-details="auto"
          v-model="date"
        )
      v-col(
        cols="6" 
        v-for="(item, index) in rackList" :key="index"
      )
        v-text-field(
          :label="item.code"
          dense
          hide-details="auto"
          v-model="quantities[item.id]"
          type="number"
          hide-spin-buttons
          clearable
          :error-messages="rackEggPostErrors[`data.${item.id}.incubator_rack_id`] || rackEggPostErrors[`data.${item.id}.quantity`]"
        )
      v-col(cols="12")
        v-btn(
          color="green"
          dark
          block
          @click="store"
          :loading="rackEggPosting"
        ) store
</template>
<style lang="sass" scoped>
  ::v-deep input::-webkit-outer-spin-button, ::v-deep input::-webkit-inner-spin-button 
    -webkit-appearance: none
    margin: 0
</style>
<script>
// import incubatorRepository from '@/repositories/incubator.repository'
import rackEggRepository from '@/repositories/rack-egg.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

// const [incubatorRackVars, incubatorRackVarNames] = requestVars({ identifier: 'incubator-rack' })
const [rackEggVars, rackEggVarNames] = requestVars({ identifier: 'rack-egg', request: 'post', hasData: false })

// const incubatorRackHandler = new VueRequestHandler(null, incubatorRackVarNames, { hasList: false })
const rackEggHandler = new VueRequestHandler(null, rackEggVarNames)

const inputVars = () => ({
  date: Window.getCurrentDate(),
  quantities: {},
})

export default {
  name: 'CreateManyIncubators',
  props: {
    incubatorRackListItems: Array,
  },
  data () {
    return {
      // ...incubatorRackVars,
      ...inputVars(),
      ...rackEggVars,
      incubatorRackListGroupKey: null,
      incubatorRackList: [],
    }
  },
  computed: {
    incubatorRackListGroupKeys () {
      return Object
        .keys(this.incubatorRackList)
        .map(key => {
          const { racks, ...data } = this.incubatorRackList[key]
          return data
        })
    },
    rackList () {
      return this.incubatorRackList[this.incubatorRackListGroupKey]
        ?.racks
        ?.sort((a, b) => a.id - b.id)
    },
  },
  created () {
    this.setList()
    this.getRacks()
  },
  watch: {
    incubatorRackListItems (data) {
      this.setList()
    },
    incubatorRackListGroupKey () {
      this.quantities = {}
    },
  },
  methods: {
    setList () {
      this.incubatorRackList = this.incubatorRackListItems.reduce((result, item) => {
        if (!result[item.incubator_id]) {
          const [code] = item.code.split('-')
          const racks = [item]
          result[item.incubator_id] = { id: item.incubator_id, code, racks }
          return result
        }
        result[item.incubator_id].racks.push(item)
        return result
      }, {})
    },
    // getRacks () {
    //   // const handler = incubatorRackHandler
    //   // const repository = incubatorRepository.racks
    //   // const params = { search: this.incubatorRackListSearch }
    //   const setList = ({ data }) => {
    //     this.incubatorRackList = data.reduce((result, item) => {
    //       if (!result[item.incubator_id]) {
    //         const [code] = item.code.split('-')
    //         const racks = [item]
    //         result[item.incubator_id] = { id: item.incubator_id, code, racks }
    //         return result
    //       }
    //       result[item.incubator_id].racks.push(item)
    //       return result
    //     }, {})
    //   }
    //   handler.setVue(this)
    //   handler.execute(repository, [params])
    //     .then(setList)
    // },
    async store () {
      if (!this.incubatorRackListGroupKey) return
      const { incubatorRackList, incubatorRackListGroupKey, date } = this
      const incubator = incubatorRackList[incubatorRackListGroupKey]?.code
      const confirm = await this.$confirm(`are you sure you want to load ${incubator}?`)
      if (!confirm) return
      const data = Object
        .keys(this.quantities)
        .filter(key => this.quantities[key])
        .reduce((result, key) => {
          result[key] = {
            incubator_rack_id: key,
            quantity: this.quantities[key],
          }
          return result
        }, {})
      const handler = rackEggHandler
      const repository = rackEggRepository.storeMany
      handler.setVue(this)
      handler.execute(repository, [{ data, date }])
        .then(() => this.$resetInputData(inputVars()))
    },
  },
}
</script>